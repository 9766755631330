export const conversionRate = 16.5;
export const convertToGHS = (usdPrice) => parseFloat(usdPrice * conversionRate);


// EV Cars List
export const vehicles = [
    {
        id: 1,
        name: "Nami Air 430km",
        priceUSD: 18865,
        consumption: 15, // kWh/100km
        currentImageIndex: 0,
        specs: [
            "430 km range",
            "Electric Motor",
            "Power: 110 kW",
            "Energy Consumption: 15 kWh/100km",
            "Fast Charging Support"
        ],
        images: [
            "/images/nami-air-plus-cover.jpg",
            "/images/nami-air-plus-front.jpg",
            "/images/nami-air-plus-side.jpg",
            "/images/nami-air-plus-interior.jpg",
            "/images/nami-air-plus-back.jpg"
        ],
        get priceGHS() {
            return convertToGHS(this.priceUSD);
        }
    },
    {
        id: 2,
        name: "Nano 330 Elegant",
        priceUSD: 16500,
        consumption: 14, // kWh/100km
        currentImageIndex: 0,
        specs: [
            "330 km range",
            "Electric Motor",
            "Power: 90 kW",
            "Energy Consumption: 14 kWh/100km",
            "Display Screen",
        ],
        images: [
            "/images/nano-elegant-cover.jpg",
            "/images/nano-standard-front.jpg",
            "/images/nano-standard-side.jpg",
            "/images/nano-standard-interior.jpg",
            "/images/nano-standard-back.jpg",
        ],
        get priceGHS() {
            return convertToGHS(this.priceUSD);
        }
    },
    {
        id: 3,
        name: "Nano 330 Standard",
        priceUSD: 15500,
        consumption: 13, // kWh/100km
        currentImageIndex: 0,
        specs: [
            "330 km range",
            "Electric Motor",
            "Power: 85 kW",
            "Energy Consumption: 13 kWh/100km",
            "Basic Features",
        ],
        images: [
            "/images/nano-standard-cover.jpg",
            "/images/nano-standard-front.jpg",
            "/images/nano-standard-side.jpg",
            "/images/nano-elegant-interior.jpg",
            "/images/nano-standard-back.jpg",
        ],
        get priceGHS() {
            return convertToGHS(this.priceUSD);
        }
    },
    {
        id: 4,
        name: "Neta Aya 2024",
        priceUSD: 18717,
        consumption: 16, // kWh/100km
        currentImageIndex: 0,
        specs: [
            "480 km range",
            "Electric Motor",
            "Power: 170 kW",
            "Energy Consumption: 16 kWh/100km",
            "0-100 km/h in 6.5s",
        ],
        images: [
            "/images/neta-aya-cover.jpg",
            "/images/neta-aya-front.jpg",
            "/images/neta-aya-side.jpg",
            "/images/neta-aya-interior.jpg",
            "/images/neta-aya-back.jpg"
        ],
        get priceGHS() {
            return convertToGHS(this.priceUSD);
        }
    },
    {
        id: 5,
        name: "Neta U 400",
        priceUSD: 19500,
        consumption: 15.5, // kWh/100km
        currentImageIndex: 0,
        specs: [
            "400 km range",
            "Electric Motor",
            "Power: 160 kW",
            "Advanced Safety Features",
            "Spacious Interior",
        ],
        images: [
            "/images/neta-u-cover.jpg",
            "/images/neta-u-front.jpg",
            "/images/neta-u-side.jpg",
            "/images/neta-u-interior.jpg",
            "/images/neta-u-back.jpg"
        ],
        get priceGHS() {
            return convertToGHS(this.priceUSD);
        }
    },
    {
        id: 6,
        name: "Neta X 400/500",
        priceUSD: 22500,
        consumption: 15, // kWh/100km
        currentImageIndex: 0,
        specs: [
            "500 km range",
            "Electric Motor",
            "Power: 180 kW",
            "Luxury Interior",
            "Premium Features"
        ],
        images: [
            "/images/neta-x-cover.jpg",
            "/images/neta-x-front.jpg",
            "/images/neta-x-side.jpg",
            "/images/neta-x-interior.jpg",
            "/images/neta-x-back.jpg"
        ],
        get priceGHS() {
            return convertToGHS(this.priceUSD);
        }
    },
    {
        id: 7,
        name: "Xpeng G9 702 Pro 2024",
        priceUSD: 35000,
        consumption: 17, // kWh/100km
        currentImageIndex: 0,
        specs: [
            "702 km range",
            "Electric Motor",
            "Power: 200 kW",
            "High Performance",
            "Advanced Autonomous Features",
        ],
        images: [
            "/images/xpeng-g9-cover.jpg",
            "/images/xpeng-g9-front.jpg",
            "/images/xpeng-g9-side.jpg",
            "/images/xpeng-g9-interior.jpg",
            "/images/xpeng-g9-back.jpg"
        ],
        get priceGHS() {
            return convertToGHS(this.priceUSD);
        }
    },
    {
        id: 8,
        name: "BYD Sealion 610",
        priceUSD: 32000,
        consumption: 16, // kWh/100km
        currentImageIndex: 0,
        specs: [
            "High Range Capacity",
            "Electric Motor",
            "Power: 190 kW",
            "Luxury Interior",
            "Advanced Safety Systems",
        ],
        images: [
            "/images/byd-seal-cover.jpg",
            "/images/byd-seal-front.jpg",
            "/images/byd-seal-side.jpg",
            "/images/byd-seal-interior.jpg",
            "/images/byd-seal-back.jpg"
        ],
        get priceGHS() {
            return convertToGHS(this.priceUSD);
        }
    }
];

// ICE Cars List
export const iceModels = [
    {
        id: 1,
        name: "Mitsubishi L200 2024",
        priceUSD: 36700,
        priceGHS: 605550,
        fuelEfficiency: 8.5, // L/100km
        specs: ["600 km range", "2.4L Diesel Engine", "150 kW Power", "Fuel Consumption: 8.5 L/100km", "Top Speed: 170 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 2,
        name: "Suzuki Swift",
        priceUSD: 18000,
        priceGHS: 297000,
        fuelEfficiency: 7.0, // L/100km
        specs: ["430 km range", "1.2L Petrol Engine", "70 kW Power", "Fuel Consumption: 7.0 L/100km", "Top Speed: 170 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 3,
        name: "Suzuki S-Presso",
        priceUSD: 13400,
        priceGHS: 221100,
        fuelEfficiency: 6.2, // L/100km
        specs: ["400 km range", "1.0L Petrol Engine", "50 kW Power", "Fuel Consumption: 6.2 L/100km", "Top Speed: 155 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 4,
        name: "Suzuki Baleno",
        priceUSD: 22000,
        priceGHS: 363000,
        fuelEfficiency: 7.1, // L/100km
        specs: ["460 km range", "1.2L Petrol Engine", "80 kW Power", "Fuel Consumption: 7.1 L/100km", "Top Speed: 165 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 5,
        name: "Hyundai Sonata",
        priceUSD: 37000,
        priceGHS: 610500,
        fuelEfficiency: 8.0, // L/100km
        specs: ["550 km range", "2.0L Petrol Engine", "105 kW Power", "Fuel Consumption: 8.0 L/100km", "Top Speed: 190 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 6,
        name: "Hyundai Accent",
        priceUSD: 22500,
        priceGHS: 363000,
        fuelEfficiency: 7.0, // L/100km
        specs: ["480 km range", "1.6L Petrol Engine", "90 kW Power", "Fuel Consumption: 7.0 L/100km", "Top Speed: 175 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 7,
        name: "Toyota Hilux - Automatic",
        priceUSD: (890000 / 16.5).toFixed(2), // GHS to USD
        priceGHS: 890000,
        fuelEfficiency: 9.0, // L/100km
        specs: ["550 km range", "2.8L Diesel Engine", "130 kW Power", "Fuel Consumption: 9.0 L/100km", "Top Speed: 165 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 8,
        name: "Toyota Hilux - Manual",
        priceUSD: (780000 / 16.5).toFixed(2), // GHS to USD
        priceGHS: 780000,
        fuelEfficiency: 9.0, // L/100km
        specs: ["550 km range", "2.8L Diesel Engine", "130 kW Power", "Fuel Consumption: 9.0 L/100km", "Top Speed: 165 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 9,
        name: "Toyota Corolla - 1.6L",
        priceUSD: (695000 / 16.5).toFixed(2), // GHS to USD
        priceGHS: 695000,
        fuelEfficiency: 7.5, // L/100km
        specs: ["600 km range", "1.6L Petrol Engine", "100 kW Power", "Fuel Consumption: 7.5 L/100km", "Top Speed: 180 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 10,
        name: "Toyota Corolla - 1.8L",
        priceUSD: (787000 / 16.5).toFixed(2), // GHS to USD
        priceGHS: 787000,
        fuelEfficiency: 7.2, // L/100km
        specs: ["610 km range", "1.8L Petrol Engine", "105 kW Power", "Fuel Consumption: 7.2 L/100km", "Top Speed: 185 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 11,
        name: "Honda CRV",
        priceUSD: 60000,
        priceGHS: 1002000,
        fuelEfficiency: 7.8, // L/100km
        specs: ["500 km range", "2.0L Petrol Engine", "110 kW Power", "Fuel Consumption: 7.8 L/100km", "Top Speed: 180 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 12,
        name: "Honda Accord",
        priceUSD: 59000,
        priceGHS: 985300,
        fuelEfficiency: 7.5, // L/100km
        specs: ["600 km range", "1.5L Petrol Engine", "120 kW Power", "Fuel Consumption: 7.5 L/100km", "Top Speed: 200 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 13,
        name: "Honda Civic",
        priceUSD: 44000,
        priceGHS: 734800,
        fuelEfficiency: 7.3, // L/100km
        specs: ["520 km range", "1.5L Petrol Engine", "100 kW Power", "Fuel Consumption: 7.3 L/100km", "Top Speed: 185 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 14,
        name: "Kia Morning - Full Option",
        priceUSD: 16500,
        priceGHS: 272250,
        fuelEfficiency: 6.5, // L/100km
        specs: ["400 km range", "1.0L Petrol Engine", "60 kW Power", "Fuel Consumption: 6.5 L/100km", "Top Speed: 160 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 15,
        name: "Kia Morning - Standard",
        priceUSD: 14900,
        priceGHS: 245850,
        fuelEfficiency: 6.5, // L/100km
        specs: ["400 km range", "1.0L Petrol Engine", "60 kW Power", "Fuel Consumption: 6.5 L/100km", "Top Speed: 160 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 16,
        name: "Kia Pegas",
        priceUSD: 17980,
        priceGHS: 296670,
        fuelEfficiency: 6.9, // L/100km
        specs: ["450 km range", "1.4L Petrol Engine", "85 kW Power", "Fuel Consumption: 6.9 L/100km", "Top Speed: 170 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    }
];

